<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <Button
          label="Add New"
          icon="pi pi-plus"
          class="ml-2 p-button-success"
          @click="$router.push('gifts/add')"
        />
        <!-- <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
        <Button
          label="Get All"
          icon="pi pi-eye"
          class="ml-2 p-button-info"
          @click="getAll()"
          v-if="showAll == 1"
        />
        <Button
          v-else
          label="Get Done Only"
          icon="pi pi-eye"
          @click="getAll()"
          class="ml-2 p-button-danger"
        />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="show {first} to {last} from {totalRecords}"
    >
      <template #header>
        <div class="table-header">
          Geschenke Aufführen

          <div style="text-align: left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Select Columns"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>Keine Daten</template>
      <template #loading>Daten werden geladen, bitte warten... </template>
      <Column
        filterMatchMode="contains"
        field="id"
        header="ID"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['id']"
            class="p-column-filter"
            placeholder="Search By ID"
          />
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="name"
        header="Name"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['name']"
            class="p-column-filter"
            placeholder="Search By Name"
            filterMatchMode="contains"
          />
        </template>
      </Column>

      <Column filterMatchMode="contains" field="phone" header="phone">
        <template #body="slotProps">
          {{ slotProps.data.phone }}
        </template>
      </Column>


      <Column filterMatchMode="contains" field="usersId.email" header="Client">
        <template #body="slotProps">
          {{ slotProps.data.usersId.fname }} {{ slotProps.data.usersId.lname }}
        </template>
      </Column>
     
      <Column
        filterMatchMode="contains"
        field="paymentMethod"
        header="Payment Method"
      >
        <template #body="slotProps">
          {{ slotProps.data.paymentMethod }}
          -
          {{
            slotProps.data.paymentDone ? "And Done" : "But Not Complite"
          }}
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="depoist"
        header="Restwert"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          {{ slotProps.data.price }} - {{ slotProps.data.depoist }} =
          {{ slotProps.data.price - slotProps.data.depoist }}€
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="createdAt"
        header="Date"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['createdAt']"
            class="p-column-filter"
            placeholder="Search By Date"
          />
        </template>
        <template #body="slotProps">
          {{ $durationFormatFullDate(slotProps.data.createdAt) }}
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="created_at"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #filter>
          {{
            Number(
              list
                .filter((col) => col.ended == false)
                .map((col) => col.price)
                .reduce((x, y) => x + y, 0)
            ) -
            Number(
              list
                .filter((col) => col.ended == false)
                .map((col) => col.depoist)
                .reduce((x, y) => x + y, 0)
            )
          }}
          €
        </template>
        <template #body="slotProps">
          <Button
            type="button"
            @click="$router.push('gifts/edit/' + slotProps.data.id)"
            icon="pi pi-eye"
            class="p-button-info p-mr-2 p-button-rounded"
          ></Button>

          <Button
            type="button"
            icon="pi pi-lock"
            v-if="!slotProps.data.ended"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning ml-2 p-button-rounded"
          ></Button>

          <Button
            type="button"
            @click="$router.push('gifts/show/' + slotProps.data.id)"
            icon="pi pi-print"
            class="p-button-success ml-2 p-button-rounded"
          ></Button>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      filters: {},
      showAll: 0,
    };
  },
  methods: {
    getAll() {
      this.showAll = this.showAll == 1 ? 0 : 1;
      this.getData();
    },
    getData() {
      this.$http.get(`gifts?ended=${this.showAll}`).then(
        (response) => {
          this.loading = false;
          const list = response.data.data;

          for (let item of list) {
            item.id = this.$codePadding(item.id);
            item.name = item.fname + " " + item.lname;
          }

          this.list = list;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    deleteItem(id) {
      this.$http.put(`gifts/${id}`, { ended: true }).then(
        () => {
          this.getData();
          this.$toast.add({
            severity: "error",
            summary: "Done Successfully",
            detail: "Updated Successfully",
            life: 3000,
          });
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    },
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>
